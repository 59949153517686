"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const elementsInteractions_1 = require("./elementsInteractions");
const objserver_1 = require("./objserver");
window.addEventListener("DOMContentLoaded", () => {
    (0, elementsInteractions_1.setNavActive)(null);
    (0, elementsInteractions_1.listenContactMe)();
    (0, elementsInteractions_1.initListeners)();
    (0, objserver_1.createObserver)();
});
