"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.attachEventListener = attachEventListener;
exports.attachEventListeners = attachEventListeners;
exports.toggleSpinner = toggleSpinner;
function attachEventListener(elementId, eventType, callback) {
    const element = document.getElementById(elementId);
    if (!element) {
        return;
    }
    element.addEventListener(eventType, callback);
}
function attachEventListeners(selector, eventType, callback) {
    document.querySelectorAll(selector).forEach((element) => {
        element.addEventListener(eventType, callback);
    });
}
function toggleSpinner(force) {
    const spinnerEl = document.querySelector("#modal_spinner");
    const modalBackdropEl = document.querySelector("#modal_backdrop");
    if (!spinnerEl || !modalBackdropEl) {
        return;
    }
    spinnerEl.classList.toggle("hidden", force);
    modalBackdropEl.classList.toggle("hidden", force);
}
