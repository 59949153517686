"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createObserver = createObserver;
function createObserver() {
    const menuItems = document.querySelectorAll("body > header nav ul li");
    const sections = document.querySelectorAll("main section header h2");
    const options = {
        root: null,
        rootMargin: "0px",
        threshold: 0.5,
    };
    const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            const target = entry.target;
            const id = target.dataset.id;
            const menuItem = document.querySelector(`body > header nav ul li[data-id="${id}"]`);
            console.log(target.dataset.id, entry.intersectionRatio);
            if (!entry.isIntersecting) {
                return;
            }
            menuItems.forEach((item) => item.classList.remove("nav-item-active"));
            if (!menuItem) {
                return;
            }
            menuItem.classList.add("nav-item-active");
        });
    }, options);
    sections.forEach((section) => {
        observer.observe(section);
    });
}
