"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setNavActive = setNavActive;
exports.closeModal = closeModal;
exports.listenContactMe = listenContactMe;
exports.initListeners = initListeners;
const misc_1 = require("./misc");
const sendMessageClient_1 = require("./sendMessageClient");
function toggleModal(force = false) {
    let modalEl = document.getElementById("modal");
    if (modalEl === null) {
        return;
    }
    modalEl.classList.toggle("modal--visible", !force);
    document.body.classList.toggle("overflow-hidden", !force);
}
function toggleBackdrop(force = false) {
    let backDropEl = document.getElementById("backdrop");
    if (backDropEl === null) {
        return;
    }
    backDropEl.classList.toggle("hidden", force);
}
function setNavActive(e) {
    let url = new URL(window.location.href);
    let id = url.hash.replace(/#/, "");
    const currentTarget = e === null || e === void 0 ? void 0 : e.currentTarget;
    if ((currentTarget === null || currentTarget === void 0 ? void 0 : currentTarget.dataset.id) !== undefined) {
        id = currentTarget.dataset.id;
    }
    const h2Els = [...document.querySelectorAll("main section header h2")];
    const ids = h2Els
        .map((i) => {
        let el = i;
        return el.dataset.id;
    })
        .filter((i) => i !== "");
    let navItems = document.querySelectorAll("header nav ul li");
    navItems.forEach((el) => el.classList.remove("nav-item-active"));
    let index = ids.indexOf(id);
    if (index === -1) {
        index = 0;
    }
    navItems[index].classList.add("nav-item-active");
    console.log(e);
    if (e === undefined) {
        window.scrollTo({
            top: 0,
            behavior: "instant",
        });
        return;
    }
    scrollToElementWithOffset(h2Els[index]);
}
function scrollToElementWithOffset(element) {
    var _a;
    const headerOffset = (_a = document.querySelector("header")) === null || _a === void 0 ? void 0 : _a.offsetHeight;
    if (!headerOffset) {
        return;
    }
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.scrollY - headerOffset;
    window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
    });
}
function closeModal() {
    toggleModal(true);
    toggleBackdrop(true);
}
function listenContactMe() {
    let element = document.getElementById("contact-me");
    if (!element) {
        return;
    }
    element.addEventListener("click", () => {
        toggleModal(false);
        toggleBackdrop(false);
    });
}
function handleBurgerClick() {
    let sidebarEl = document.getElementById("sidemenu");
    if (!sidebarEl) {
        return;
    }
    sidebarEl.classList.toggle("show", true);
}
function handleCrossClick() {
    let sidebarEl = document.getElementById("sidemenu");
    if (!sidebarEl) {
        return;
    }
    sidebarEl.classList.toggle("show", false);
}
function initListeners() {
    (0, misc_1.attachEventListeners)("header nav ul li a", "click", setNavActive);
    (0, misc_1.attachEventListener)("close", "click", closeModal);
    (0, misc_1.attachEventListener)("backdrop", "click", closeModal);
    (0, misc_1.attachEventListener)("burger_icon", "click", handleBurgerClick);
    (0, misc_1.attachEventListener)("cross_button", "click", handleCrossClick);
    (0, misc_1.attachEventListener)("send_message", "click", sendMessageClient_1.sendMessage);
    (0, misc_1.attachEventListener)("name", "change", sendMessageClient_1.handleValidate);
    (0, misc_1.attachEventListener)("email", "change", sendMessageClient_1.handleValidate);
    (0, misc_1.attachEventListener)("phone", "change", sendMessageClient_1.handleValidate);
}
